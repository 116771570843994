import classNames from 'classnames';
import { createContext, useContext } from 'react';

export interface LayoutPaddingProps {
  children: React.ReactNode;
  slim?: boolean;
  center?: boolean;
  stretch?: boolean;
  large?: boolean;
  horizontal?: boolean;
}

const context = createContext<LayoutPaddingProps | null>(null);

export function LayoutPaddingOffset(props: { children: React.ReactNode }) {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('<LayoutPaddingOffset /> must be used within a <LayoutPadding />');
  }

  return (
    <div
      className={classNames('relative w-full', {
        '-mx-4 first:-mt-2 sm:first:-mt-4 sm:-mx-8': ctx.slim,
        '-mx-4 first:-mt-4 sm:-mx-8 sm:first:-mt-8': !ctx.slim && !ctx.large,
        '-mx-4 md:-mx-8 lg:first:-mt-10 lg:-mx-16': ctx.large,
      })}
    >
      {props.children}
    </div>
  );
}

export default function LayoutPadding(props: LayoutPaddingProps) {
  return (
    <context.Provider value={props}>
      <div
        className={classNames('max-w-screen-xl w-full flex flex-col flex-1', {
          'p-4 sm:px-8': props.slim && !props.horizontal,
          'px-4 sm:px-8': props.slim && props.horizontal,
          'p-4 sm:p-8': !props.slim && !props.large,
          'p-4 md:p-8 lg:py-10 lg:px-16': props.large,
          'items-center justify-center': props.center,
          'items-stretch': props.stretch && !props.center,
        })}
      >
        {props.children}
      </div>
    </context.Provider>
  );
}
